import React, { useCallback, useEffect, useState, useRef } from "react";
import exportFromJSON from "export-from-json";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  fetchTickets,
  getTicketsError,
  getTicketsStatus,
  selectAllTickets,
  selectAPICount,
} from "../../redux/ticketsSlice";
import Moment from "react-moment";
import "moment-timezone";
import moment from "moment";
import { user_id } from "../../redux/userSlice";
import DatePicker from "react-datepicker";
import {
  UncontrolledDropdown,
  Card,
  Row,
  Col,
  Modal,
  ModalBody,
  Button,
  Spinner,
  FormGroup,
  Label,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  TabContent,
  TabPane,
} from "reactstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon, PreviewCard } from "../Component";
import "../../pages/total.css";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { selectSavedValue, selectSavedLabel } from "../../redux/sessionClientSlice";
import { username, role } from "../../redux/userSlice";

const Export = ({ data, startDate, endDate, clientValueLabel, user, userrole, logoUrl }) => {
  const [modal, setModal] = useState(false);

  // const exportNoDataAlert = () => {
  //   toast.error("There is no data available for export!", {
  //     position: "bottom-right",
  //     autoClose: 2000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: false,
  //   });
  // };

  useEffect(() => {
    if (modal === true) {
      setTimeout(() => setModal(false), 3000);
    }
  }, [modal]);

  const fileName = `Ticket Data [${startDate} - ${endDate}]`;

  const exportCSV = () => {
    // if(ticketData.length === 0) {
    //   exportNoDataAlert();
    //   return;
    // }
    const exportType = exportFromJSON.types.csv;
    const modifiedData = data.map((row) => ({
      ...row,
      Amount: "$ " + row.Amount.toFixed(2),
      Activated: row.Activated ? "Yes" : "No",
      Validated: row.Validated ? "Yes" : "No",
      PurchasedDate:
        new Date(row.PurchasedDate).getFullYear() >= 2001
          ? moment(row.PurchasedDate).format("MMMM Do YYYY, h:mm a")
          : "-",
      ActivatedDate:
        // new Date(row.ActivatedDate).getFullYear() >= 2001 ? moment(row.ActivatedDate).format("MMMM Do YYYY, h:mm a") : "-",
        row.Activated
          ? new Date(row.ActivatedDate).getFullYear() >= 2001
            ? moment(row.ActivatedDate).format("MMMM Do YYYY, h:mm a")
            : moment(row.ValidatedDate).format("MMMM Do YYYY, h:mm a")
          : "-",
      ValidatedDate:
        new Date(row.ValidatedDate).getFullYear() >= 2001
          ? moment(row.ValidatedDate).format("MMMM Do YYYY, h:mm a")
          : "-",
      ExpiryDate:
        new Date(row.ExpiryDate).getFullYear() >= 2001 ? moment(row.ExpiryDate).format("MMMM Do YYYY, h:mm a") : "-",
    }));
    const updatedData = modifiedData.map(({ Status, ...rest }) => rest);
    exportFromJSON({ data: updatedData, fileName, exportType });
  };

  const exportExcel = () => {
    // if(ticketData.length === 0) {
    //   exportNoDataAlert();
    //   return;
    // }
    const exportType = exportFromJSON.types.xls;
    const modifiedData = data.map((row) => ({
      ...row,
      Amount: "$ " + row.Amount.toFixed(2),
      Activated: row.Activated ? "Yes" : "No",
      Validated: row.Validated ? "Yes" : "No",
      PurchasedDate:
        new Date(row.PurchasedDate).getFullYear() >= 2001
          ? moment(row.PurchasedDate).format("MMMM Do YYYY, h:mm a")
          : "-",
      ActivatedDate:
        // new Date(row.ActivatedDate).getFullYear() >= 2001 ? moment(row.ActivatedDate).format("MMMM Do YYYY, h:mm a") : "-",
        row.Activated
          ? new Date(row.ActivatedDate).getFullYear() >= 2001
            ? moment(row.ActivatedDate).format("MMMM Do YYYY, h:mm a")
            : moment(row.ValidatedDate).format("MMMM Do YYYY, h:mm a")
          : "-",
      ValidatedDate:
        new Date(row.ValidatedDate).getFullYear() >= 2001
          ? moment(row.ValidatedDate).format("MMMM Do YYYY, h:mm a")
          : "-",
      ExpiryDate:
        new Date(row.ExpiryDate).getFullYear() >= 2001 ? moment(row.ExpiryDate).format("MMMM Do YYYY, h:mm a") : "-",
    }));
    const updatedData = modifiedData.map(({ Status, ...rest }) => rest);
    exportFromJSON({ data: updatedData, fileName, exportType });
  };

  const convertGMTtoLocal = (gmtDateStr) => {
    const gmtDate = new Date(gmtDateStr);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return gmtDate.toLocaleString("en-US", options).replace(/(,|\d+:\d+):\d+\s(AM|PM)/, "$1 $2");
  };

  //Check local time
  // useEffect(()=>{
  //   const currDate = new Date();
  //   window.alert(currDate.toLocaleString());
  // },[])

  // const exportPDF = () => {
  //   console.log("Export data:", data);
  //   const doc = new jsPDF("landscape");
  //   doc.text("Ticket Data", 10, 10);

  //   const tableData = data.map((row) =>
  //     exportColumns.map((col) => {
  //       const selectorResult = col.selector(row);
  //       if (
  //         selectorResult === row.PurchasedDate ||
  //         selectorResult === row.ActivatedDate ||
  //         selectorResult === row.ValidatedDate ||
  //         selectorResult === row.ExpiryDate
  //       ) {
  //         return convertGMTtoLocal(selectorResult);
  //       } else if (typeof col.selector === "function") {
  //         return selectorResult;
  //       } else {
  //         return row[col.selector];
  //       }
  //     })
  //   );

  //   doc.autoTable({
  //     head: [exportColumns.map((col) => col.name)],
  //     body: tableData,
  //   });

  //   doc.output("dataurlnewwindow", { filename: fileName });
  //   doc.save(fileName);
  // };
  const exportPDF = () => {
    // if(ticketData.length === 0) {
    //   exportNoDataAlert();
    //   return;
    // }
    console.log("Export data:", data);
    const doc = new jsPDF("landscape");

    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = logoUrl;

    logo.onload = () => {
      const imgWidth = 30;
      const imgHeight = 30;
      const logoX = doc.internal.pageSize.width - imgWidth - 10;
      const logoY = 2;
      doc.addImage(logo, "JPEG", logoX, logoY, imgWidth, imgHeight);

      doc.setFont("times", "bold");
      doc.setFontSize(20);
      doc.setTextColor(255, 0, 0);
      {
        userrole === 3 ? doc.text(clientValueLabel, 10, 20) : doc.text(user, 10, 20);
      }

      doc.setFont("times", "normal");
      doc.setFontSize(16);
      doc.setTextColor(0);
      doc.text("Ticket Data", 10, 40);

      const tableData = data.map((row) =>
        exportColumns.map((col) => {
          const selectorResult = col.selector(row);
          // if (
          //   selectorResult === row.PurchasedDate ||
          //   selectorResult === row.ActivatedDate ||
          //   selectorResult === row.ValidatedDate ||
          //   selectorResult === row.ExpiryDate
          // ) {
          //   return moment(selectorResult).format("M/D/YYYY, h:mm:ss A");
          //   }
          if (typeof col.selector === "function") {
            return selectorResult;
          } else {
            return row[col.selector];
          }
        })
      );

      doc.autoTable({
        head: [exportColumns.map((col) => col.name)],
        body: tableData,
        startY: 50,
      });

      doc.save(fileName);
    };

    logo.onerror = () => {
      console.error("Failed to load logo image");

      const tableData = data.map((row) =>
        exportColumns.map((col) => {
          const selectorResult = col.selector(row);
          // if (
          //   selectorResult === row.PurchasedDate ||
          //   selectorResult === row.ActivatedDate ||
          //   selectorResult === row.ValidatedDate ||
          //   selectorResult === row.ExpiryDate
          // ) {
          //   return moment(selectorResult).format("M/D/YYYY, h:mm:ss A");
          // }
          if (typeof col.selector === "function") {
            return selectorResult;
          } else {
            return row[col.selector];
          }
        })
      );

      doc.autoTable({
        head: [exportColumns.map((col) => col.name)],
        body: tableData,
      });

      doc.save(fileName);
    };
  };

  const copyToClipboard = () => {
    setModal(true);
  };
  if (!data || data.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="dt-export-buttons d-flex align-center" style={{ marginTop: -20 }}>
        <div className="dt-export-title d-none d-md-inline-block">Export</div>
        <div className="dt-buttons btn-group flex-wrap">
          <CopyToClipboard text={JSON.stringify(data)}>
            <Button className="buttons-copy buttons-html5" onClick={() => copyToClipboard()}>
              <span>Copy</span>
            </Button>
          </CopyToClipboard>{" "}
          <button className="btn btn-secondary buttons-csv buttons-html5" type="button" onClick={() => exportCSV()}>
            <span>CSV</span>
          </button>{" "}
          <button className="btn btn-secondary buttons-excel buttons-html5" type="button" onClick={() => exportExcel()}>
            <span>Excel</span>
          </button>{" "}
          <button className="btn btn-secondary buttons-pdf buttons-html5" type="button" onClick={() => exportPDF()}>
            <span>Pdf</span>
          </button>{" "}
        </div>
      </div>
      <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
        <ModalBody className="text-center m-2">
          <h5>Copied to clipboard</h5>
        </ModalBody>
        <div className="p-3 bg-light">
          <div className="text-center">Copied {data.length} rows to clipboard</div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const ExpandableRowDetailsComponent = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getFormattedAddress = (address) => {
    const replaceAddresses = ["Louisville, KY, USA", "1257 S 3rd St, Louisville, KY 40203, USA"];
    if (replaceAddresses.includes(address)) {
      return "-";
    }
    return address;
  };

  return (
    <React.Fragment>
      <Button color="" onClick={toggleModal}>
        <Icon name="eye"></Icon>
      </Button>
      <Modal isOpen={modalOpen} toggle={toggleModal} className="modal-dialog-centered">
        <ModalBody>
          <a href="#cancel" className="close">
            {" "}
            <Icon name="cross-sm" onClick={toggleModal}></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">More Details</h5>
          </div>
          <PreviewCard>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12" className="fw-bold" style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
                    <h5 className="title">Customer Identification</h5>
                  </Col>
                  <br />
                </Row>
                <Row>
                  <br />
                  <Col sm="10" style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
                    {data.licensefront ? (
                      <span className="dtr-data">
                        <img
                          src={data.licensefront}
                          alt="License Front"
                          onError={(e) => (e.target.src = "/not-found-image.jpg")}
                        />
                      </span>
                    ) : (
                      <img src="https://zed.digital/img/app/v1.jpg" alt="Identification 1" />
                    )}
                  </Col>
                </Row>
                <Row>
                  <br />
                </Row>
                <Row>
                  {/* <Col sm="4" className="fw-bold" style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
                    License Back
                  </Col> */}
                  <Col sm="10" style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
                    {data.licenseback ? (
                      <span className="dtr-data">
                        <img
                          src={data.licenseback}
                          alt="License Back"
                          onError={(e) => (e.target.src = "/not-found-image.jpg")}
                        />
                      </span>
                    ) : (
                      <img src="https://zed.digital/img/app/v1.jpg" alt="Identification 2" />
                    )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </PreviewCard>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const ExpandableRowComponent = ({ data }) => {
  const getFormattedAddress = (address) => {
    const replaceAddresses = ["Louisville, KY, USA", "1257 S 3rd St, Louisville, KY 40203, USA"];
    if (replaceAddresses.includes(address)) {
      return "-";
    }
    return address;
  };

  return (
    <ul className="dtr-details p-2 border-bottom ml-1">
      <li style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
        <span style={{ marginRight: "1rem" }} className=" dtr-title">
          Activated
        </span>{" "}
        <span className="dtr-data">{data.Activated}</span>
      </li>
      <li style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
        <span className="dtr-title ">Activated Date</span>{" "}
        <span className="dtr-data">
          {/* {data.ActivatedDate && new Date(data.ActivatedDate).getFullYear() >= 2001 ? (
            <Moment format="MMMM Do YYYY, h:mm a">{data.ActivatedDate}</Moment>
          ) : "-"} */}
          {data.Activated == "YES" ? (
            new Date(data.ActivatedDate).getFullYear() >= 2001 ? (
              <Moment format="MMMM Do YYYY, h:mm a">{data.ActivatedDate}</Moment>
            ) : (
              <Moment format="MMMM Do YYYY, h:mm a">{data.ValidatedDate}</Moment>
            )
          ) : (
            "-"
          )}
        </span>
      </li>
      <li style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
        <span className="dtr-title">Validated Date </span>{" "}
        <span className="dtr-data">
          {data.ActivatedDate && new Date(data.ValidatedDate).getFullYear() >= 2001 ? (
            <Moment format="MMMM Do YYYY, h:mm a">{data.ValidatedDate}</Moment>
          ) : (
            "-"
          )}
        </span>
      </li>
      <li style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
        <span className="dtr-title">Expiry Date </span>{" "}
        <span className="dtr-data">
          {data.ActivatedDate && new Date(data.ExpiryDate).getFullYear() >= 2001 ? (
            <Moment format="MMMM Do YYYY, h:mm a">{data.ExpiryDate}</Moment>
          ) : (
            "-"
          )}
        </span>
      </li>
      <li style={{ padding: "0.25rem" }}>
        <span style={{ marginRight: "1rem" }} className="dtr-title">
          Username
        </span>{" "}
        <span className="dtr-data">{data.Username}</span>
      </li>
    </ul>
  );
};

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input
      id={rest.name}
      type="checkbox"
      className="custom-control-input form-control"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="custom-control-label" htmlFor={rest.name} />
  </div>
));

const TicketsTable = ({ pagination, expandableRows, actions, className, selectableRows, restoreScrollPosition }) => {
  const client_id = useSelector(user_id);
  const clientValueId = useSelector(selectSavedValue);
  const clientValueLabel = useSelector(selectSavedLabel);
  const userrole = useSelector(role);
  const user = useSelector(username);
  const logoUrl = process.env.REACT_APP_SIGN_IN;
  const [selectedStatus, setSelectedStatus] = useState("all");

  const dispatch = useDispatch();
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(7);
  const [refreshIntervalId, setRefreshIntervalId] = useState(null);
  const tickets = useSelector(selectAllTickets);
  const status = useSelector(getTicketsStatus);
  const error = useSelector(getTicketsError);
  const apiCount = useSelector(selectAPICount);
  const [ticketData, setTicketData] = useState([]);
  const [rowsPerPageS, setRowsPerPage] = useState(10);
  const date = new Date();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [noResultsFound2, setNoResultsFound2] = useState(false);

  const daysAgo = new Date(date.getTime());
  daysAgo.setDate(date.getDate() - 7);
  const [originalTicketData, setOriginalTicketData] = useState([]);
  const [searchedTicketData, setSearchedTicketData] = useState([]);
  const [onSearchText, setSearchText] = useState("");
  const [searchLengthErr, setSearchLengthErr] = useState(false);
  const [data, setData] = useState([]);
  const initialData = useRef([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalTicketCount, setTotalTicketCount] = useState(0);
  const [rangeDate, setRangeDate] = useState({
    start: daysAgo,
    end: new Date(),
  });
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  const [totalPage, setTotalPage] = useState(1); // Initialize totalPage state with 1
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const handlePrevPage = () => {
    setPaginationLoading(true);
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    setPaginationLoading(true);
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const moreDetailsColumn =
    process.env.REACT_APP_PANEL_TEXT === "ZIG" && client_id === 52
      ? {
          name: "More Details",
          selector: (row) => row,
          cell: (row) => (
            <div style={{ textAlign: "center", width: "100%" }}>
              <ExpandableRowDetailsComponent data={row} />
            </div>
          ),
        }
      : null;

  const columns = [
    {
      name: "Email",
      selector: (row) => row.EmailId,
      sortable: true,
      grow: 2,
    },
    {
      name: "Ticket ID",
      selector: (row) => row.TicketID,
      sortable: true,
      grow: 1,
    },
    {
      name: "Transaction ID",
      selector: (row) => row.TransactionId,
      sortable: true,
      grow: 2,
    },
    {
      name: "Purchased Date",
      selector: (row) =>
        new Date(row.PurchasedDate).getFullYear() >= 2001 ? (
          <Moment format="MMMM Do YYYY, h:mm a">{row.PurchasedDate}</Moment>
        ) : (
          "-"
        ),
      sortable: true,
      grow: 2,
      sortField: "PurchasedDate", // Add this property for sorting
      // format: (row) => <Moment format="MMMM Do YYYY, h:mm a">{row.PurchasedDate}</Moment>,
    },
    localStorage.getItem("email") === "marquette@zed.digital"
      ? {
          name: "Validated",
          selector: (row) => row.Validated,
          sortable: true,
          grow: 1,
        }
      : {
          name: "Activated",
          selector: (row) => row.Activated,
          sortable: true,
          grow: 1,
        },
    moreDetailsColumn,
  ].filter((column) => column !== null);

  useEffect(() => {
    // Set a timeout to show "No data" message after 15 seconds
    const timeoutId = setTimeout(() => {
      setShowNoDataMessage(true);
    }, 7000);

    return () => {
      clearTimeout(timeoutId); // Clear the timeout on component unmount
    };
  }, [loadingSpinner]);

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setSelectedRowsPerPage(parseInt(newRowsPerPage, 7));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page); // Update currentPage state with the new page number
  };

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setRangeDate({ start: start, end: end });
    setShowNoDataMessage(false);
    setSearchText(onSearchText);

    // Check if both start and end dates are selected
    if (start && end) {
      setLoadingSpinner(true); // Start spinner when both dates are selected
      setCurrentPage(1);
      // setSearchText(""); // Clear search text when date range changes
      setIsLoadingData(true); // Set loading state to true
    }
  };
  useEffect(() => {
    if (loadingSpinner) {
      setShowNoDataMessage(false);
    }
  }, [loadingSpinner]);

  const fetchData = useCallback(() => {
    setIsLoadingData(true); // Set loading state to true before fetching data

    if (rangeDate.start && rangeDate.end && onSearchText === "") {
      //Hide ticket id length error
      setSearchLengthErr(false);
      // Add condition to check if search bar is empty
      const startDate =
        rangeDate.start.getFullYear() + "-" + (rangeDate.start.getMonth() + 1) + "-" + rangeDate.start.getDate();
      const endDate =
        rangeDate.end.getFullYear() + "-" + (rangeDate.end.getMonth() + 1) + "-" + rangeDate.end.getDate();

      let apiEndpoint;
      if (userrole === 3 && clientValueId) {
        apiEndpoint = `${process.env.REACT_APP_CONFIG_BASE_URL}v3/GetAllTicketHistory?startDate=${startDate}&endDate=${endDate}&client_id=${clientValueId}&page=${currentPage}&pagecount=7&filter_by=transactiondate&status=${selectedStatus}`;
      }
      else {
        apiEndpoint = `${process.env.REACT_APP_CONFIG_BASE_URL}v3/GetAllTicketHistory?startDate=${startDate}&endDate=${endDate}&client_id=${client_id}&page=${currentPage}&pagecount=7&filter_by=transactiondate&status=${selectedStatus}`;
      }
      axios
        .get(apiEndpoint)
        .then((response) => {
          const { data, page } = response.data;
          setTicketData(data);
          setOriginalTicketData(data);
          setTotalTicketCount(page.totalPage);
          setSearchedTicketData(data);
          setCurrentPage2(page.currentPage); // Update the current page state based on the API response
          setTotalPage(page.totalPage); // Update totalPage state
          setLoadingSpinner(false); // Hide spinner after data is fetched
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    } else {
      setIsLoadingData(false);
      setLoadingSpinner(false);
      setPaginationLoading(false);
    }
  }, [rangeDate, client_id, clientValueId, currentPage, onSearchText, selectedStatus]);

  useEffect(() => {
    if (onSearchText !== "" && rangeDate.end) {
      let apiEndpoint;
      let searchMatchValue;
      let localLengthErr;
      let onSearchTextTrim = onSearchText.trim();
      if (/^\d+$/.test(onSearchTextTrim)) {
        searchMatchValue = "ticket";
        if (onSearchTextTrim.length < 4) {
          setSearchLengthErr(true);
          localLengthErr = true;
        } else {
          setSearchLengthErr(false);
          localLengthErr = false;
        }
      } else {
        searchMatchValue = "email";
        setSearchLengthErr(false);
        localLengthErr = false;
      }

      if (userrole === 3 && clientValueId && localLengthErr === false) {
        apiEndpoint = `${process.env.REACT_APP_CONFIG_BASE_URL}v2/searchTicket/Email?startDate=${rangeDate.start
          .toISOString()
          .slice(0, 10)}&endDate=${rangeDate.end
            .toISOString()
            .slice(0, 10)}&client_id=${clientValueId}&page=${currentPage}&pagecount=7&${searchMatchValue}=${onSearchTextTrim}&filter_by=transactiondate&status=${selectedStatus}`;
      }
      else if (localLengthErr === false) {
        apiEndpoint = `${process.env.REACT_APP_CONFIG_BASE_URL}v2/searchTicket/Email?startDate=${rangeDate.start
          .toISOString()
          .slice(0, 10)}&endDate=${rangeDate.end
            .toISOString()
            .slice(0, 10)}&client_id=${client_id}&page=${currentPage}&pagecount=7&${searchMatchValue}=${onSearchTextTrim}&filter_by=transactiondate&status=${selectedStatus}`;
      }

      if (apiEndpoint && localLengthErr === false) {
        axios
          .get(apiEndpoint)
          .then((response) => {
            const { data, page } = response.data;
            setTotalPage(page.totalPage);
            if (data.length === 0) {
              setNoResultsFound(true); // Set noResultsFound state to true if no data is found
            } else {
              setNoResultsFound(false); // Reset noResultsFound state if data is found
            }
            setSearchedTicketData(data);
          })
          .catch((error) => {
            setSearchedTicketData([]);
            if (error.response && error.response.status === 404) {
              setNoResultsFound(true); // Set noResultsFound state to true if API returns 404
            } else {
              console.error("Error fetching data: ", error);
            }
          });
      }
    }
  }, [rangeDate.end, currentPage, clientValueId]);

  useEffect(() => {
    setIsLoadingData(true); // Set loading state to true before fetching data
    fetchData(); // Fetch data
    const intervalId = setInterval(() => {
      fetchData(); // Fetch data at regular intervals
    }, 30000);
    setRefreshIntervalId(intervalId); // Set interval ID
    setIsLoadingData(false); // Set loading state to false after data fetching is completed
    setPaginationLoading(false); // Clear pagination loading state

    return () => {
      clearInterval(intervalId); // Clear interval on component unmount
    };
  }, [fetchData]);

  // const onFilterChange = (e) => {
  //   const searchText = e.target.value;
  //   setSearchText(searchText);

  //   if (e.nativeEvent.inputType === "deleteContentBackward" && searchText.length === 0) {
  //     setSearchedTicketData([...originalTicketData]); // Restore original data when search text is cleared
  //     setNoResultsFound(false); // Reset noResultsFound state
  //   } else {
  //     // Fetch data from the API based on the searched ticket ID
  //     const apiEndpoint = `${process.env.REACT_APP_CONFIG_BASE_URL}searchTicket/Email?startDate=${rangeDate.start
  //       .toISOString()
  //       .slice(0, 10)}&endDate=${rangeDate.end
  //         .toISOString()
  //         .slice(0, 10)}&client_id=${client_id}&email=${searchText}`;
  //     axios
  //       .get(apiEndpoint)
  //       .then((response) => {
  //         const { data } = response.data;
  //         if (data.length === 0) {
  //           setNoResultsFound(true); // Set noResultsFound state to true if no data is found
  //         } else {
  //           setNoResultsFound(false); // Reset noResultsFound state if data is found
  //         }
  //         setSearchedTicketData(data);
  //       })
  //       .catch((error) => {
  //         if (error.response && error.response.status === 404) {
  //           setNoResultsFound(true); // Set noResultsFound state to true if API returns 404
  //         } else {
  //           console.error("Error fetching data: ", error);
  //         }
  //       });
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearchEnteredText();
    }
  };

  const onSearchEnteredText = () => {
    // Fetch data from the API based on the searched ticket ID
    if (onSearchText !== "") {
      //Call download api for getting searched data
      fetchDownloadData();
      let apiEndpoint;
      let searchMatchValue;
      let localLengthErr;
      let onSearchTextTrim = onSearchText.trim();

      if (/^\d+$/.test(onSearchTextTrim)) {
        searchMatchValue = "ticket";
        if (onSearchTextTrim.length < 4) {
          setSearchLengthErr(true);
          localLengthErr = true;
        } else {
          setSearchLengthErr(false);
          localLengthErr = false;
        }
      } else {
        searchMatchValue = "email";
        setSearchLengthErr(false);
        localLengthErr = false;
      }
      if (userrole === 3 && clientValueId && localLengthErr === false) {
        apiEndpoint = `${process.env.REACT_APP_CONFIG_BASE_URL}v2/searchTicket/Email?startDate=${rangeDate.start
          .toISOString()
          .slice(0, 10)}&endDate=${rangeDate.end
            .toISOString()
            .slice(0, 10)}&client_id=${clientValueId}&page=${currentPage}&pagecount=7&${searchMatchValue}=${onSearchTextTrim}&filter_by=transactiondate&status=${selectedStatus}`;
      }
      else if (localLengthErr === false) {
        apiEndpoint = `${process.env.REACT_APP_CONFIG_BASE_URL}v2/searchTicket/Email?startDate=${rangeDate.start
          .toISOString()
          .slice(0, 10)}&endDate=${rangeDate.end
            .toISOString()
            .slice(0, 10)}&client_id=${client_id}&page=${currentPage}&pagecount=7&${searchMatchValue}=${onSearchTextTrim}&filter_by=transactiondate&status=${selectedStatus}`;
      }

      if (apiEndpoint && localLengthErr === false) {
        axios
          .get(apiEndpoint)
          .then((response) => {
            const { data, page } = response.data;
            setTotalPage(page.totalPage);
            if (data.length === 0) {
              setNoResultsFound(true); // Set noResultsFound state to true if no data is found
            } else {
              setNoResultsFound(false); // Reset noResultsFound state if data is found
            }
            setSearchedTicketData(data);
          })
          .catch((error) => {
            setSearchedTicketData([]);
            if (error.response && error.response.status === 404) {
              setNoResultsFound(true); // Set noResultsFound state to true if API returns 404
            } else {
              console.error("Error fetching data: ", error);
            }
          });
      }
    }
  };

  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = initialData.current.filter((item) => {
        const emailid = item?.EmailId.toLowerCase().includes(onSearchText.toLowerCase());
        const ticketid = item?.TicketID.toLowerCase().includes(onSearchText.toLowerCase());
        const transactionid = item?.TransactionId.toLowerCase().includes(onSearchText.toLowerCase());
        const Username = item?.Username.toLowerCase().includes(onSearchText.toLowerCase());
        return emailid || ticketid || transactionid || Username;
      });
      setData(filteredObject);
    } else {
      setData([...initialData.current]);
    }
    setCurrentPage(1);
  }, [onSearchText]);

  const pageNumbers = [];
  for (let i = 1; i <= totalPage; i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const displayEllipsis = currentPage >= 5 && totalPage > 5;

  // Determine the starting page number to display
  let startPage = 1;
  let endPage = totalPage;
  if (totalPage > 5) {
    startPage = Math.max(1, currentPage - 2);
    endPage = Math.min(totalPage, startPage + 4);
  }
  useEffect(() => {
    // Fetch data when currentPage changes
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    // Clear loading state when data fetching is completed
    if (!loadingSpinner) {
      setPaginationLoading(false);
    }
  }, [loadingSpinner]);

  //Export ticket data
  const [ticketHistory, setTicketHistory] = useState([]);
  const [exportStart, setExportStart] = useState(null);
  const [exportEnd, setExportEnd] = useState(null);

  const fetchDownloadData = async () => {
    try {
      let response;
      if (onSearchText === "") {
        if (userrole === 3 && clientValueId) {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}v2/DownloadAllTicketHistory?startDate=${rangeDate.start
              .toISOString()
              .slice(0, 10)}&endDate=${rangeDate.end.toISOString().slice(0, 10)}&client_id=${clientValueId}&status=${selectedStatus}`
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}v2/DownloadAllTicketHistory?startDate=${rangeDate.start
              .toISOString()
              .slice(0, 10)}&endDate=${rangeDate.end.toISOString().slice(0, 10)}&client_id=${client_id}&status=${selectedStatus}`
          );
        }
      } else {
        let searchMatchValue;
        let onSearchTextTrim = onSearchText.trim();
        if (/^\d+$/.test(onSearchTextTrim)) {
          searchMatchValue = "ticket";
        } else {
          searchMatchValue = "email";
        }
        if (userrole === 3 && clientValueId) {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}DownloadAllTicketHistory?startDate=${rangeDate.start
              .toISOString()
              .slice(0, 10)}&endDate=${rangeDate.end.toISOString().slice(0, 10)}&client_id=${clientValueId}&${searchMatchValue}=${onSearchTextTrim}&status=${selectedStatus}`
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}DownloadAllTicketHistory?startDate=${rangeDate.start
              .toISOString()
              .slice(0, 10)}&endDate=${rangeDate.end.toISOString().slice(0, 10)}&client_id=${client_id}&${searchMatchValue}=${onSearchTextTrim}&status=${selectedStatus}`
          );
        }
      }
      setTicketHistory(response.data.data);
      console.log("Ticket history", response.data.data);
      setExportStart(rangeDate.start.toISOString().slice(0, 10));
      setExportEnd(rangeDate.end.toISOString().slice(0, 10));
    } catch (error) {
      console.error("Error fetching ticket history:", error);
    }
  };

  useEffect(() => {
    fetchDownloadData();
  }, [rangeDate.start, rangeDate.end, client_id, clientValueId, selectedStatus]);

  useEffect(() => {
    if (onSearchText === "") {
      fetchDownloadData();
    }
  }, [onSearchText]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Last Week");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleDropdownChange = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (value) {
      case "Today":
        startDate = today;
        endDate = today;
        break;
      case "Last Week":
        startDate.setDate(today.getDate() - 7);
        break;
      case "Last Month":
        startDate.setMonth(today.getMonth() - 1);
        break;
      case "Last 3 Months":
        startDate.setMonth(today.getMonth() - 3);
        break;
      default:
        break;
    }
    setRangeDate({ start: startDate, end: endDate });
  };
  if (!ticketData || ticketData.length === 0 || loadingSpinner) {
    if (!showNoDataMessage) {
      // If loadingSpinner is true and 5 seconds haven't passed yet, show "Loading..." message
      return (
        <div>
          <Row className="gy-4">
            <Col sm={8}>
              <FormGroup>
                {/* <Label>Date Range</Label> */}
                <div className="d-flex align-items-end" style={{ marginTop: "-5px", marginBottom: 10 }}>
                  <div style={{ textAlign: "right" }}>
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="dropdown-container">
                      <DropdownToggle
                        color="primary"
                        className="dropdown-toggle"
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                        <Icon style={{ paddingRight: "5px" }} name="calender-date" />
                        {selectedOption}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu">
                        <DropdownItem onClick={() => handleDropdownChange("Today")}>Today</DropdownItem>
                        <DropdownItem onClick={() => handleDropdownChange("Last Week")}>Last Week</DropdownItem>
                        <DropdownItem onClick={() => handleDropdownChange("Last Month")}>Last Month</DropdownItem>
                        <DropdownItem onClick={() => handleDropdownChange("Last 3 Months")}>Last 3 Months</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="ml-2">
                    <DatePicker
                      selected={rangeDate.start}
                      startDate={rangeDate.start}
                      onChange={onRangeChange}
                      endDate={rangeDate.end}
                      selectsRange
                      className="form-control date-picker"
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Card>
            <div className="nk-ecwg nk-ecwg6">
              <div className="card-inner">
                <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <h6 className="title " style={{ textAlign: "center" }}>
                      <Spinner color="primary" />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    } else {
      // If loadingSpinner is still true after 5 seconds, show "No data" message
      return (
        <div>
          <Row className="gy-4">
            <Col sm={8}>
              <FormGroup>
                {/* <Label>Date Range</Label> */}
                <div className="d-flex align-items-end" style={{ marginTop: "-5px", marginBottom: 10 }}>
                  <div style={{ textAlign: "right" }}>
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="dropdown-container">
                      <DropdownToggle
                        color="primary"
                        className="dropdown-toggle"
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                        <Icon style={{ paddingRight: "5px" }} name="calender-date" />
                        {selectedOption}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu">
                        <DropdownItem onClick={() => handleDropdownChange("Today")}>Today</DropdownItem>
                        <DropdownItem onClick={() => handleDropdownChange("Last Week")}>Last Week</DropdownItem>
                        <DropdownItem onClick={() => handleDropdownChange("Last Month")}>Last Month</DropdownItem>
                        <DropdownItem onClick={() => handleDropdownChange("Last 3 Months")}>Last 3 Months</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="ml-2">
                    <DatePicker
                      selected={rangeDate.start}
                      startDate={rangeDate.start}
                      onChange={onRangeChange}
                      endDate={rangeDate.end}
                      selectsRange
                      className="form-control date-picker"
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className={`justify-between align-items-end g-2 ${actions ? "with-export" : ""}`}>
            <Col className="col-lg-8 text-left" sm="4">
              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                {/* <label>
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder="Search by Ticket ID"
                      onChange={(e) => onFilterChange(e)}
                    />
                  </label> */}
                {/* <ul className="nk-block-tools g-3">
                    <li style={{ width: "80%" }}>
                      <div className="form-control-wrap" style={{ width: "80%" }}>
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by Email"
                          onChange={(e) => onFilterChange(e)}
                        />
                      </div>
                    </li>
                  </ul> */}
                <div className="d-flex align-items-start justify-content-start">

                  <ul className="nk-block-tools">
                    <li>
                      <div className="form-control-wrap">
                        {
                          onSearchText ?
                            (
                              <div className="form-icon form-icon-right" onClick={() => setSearchText("")}>
                                <Icon name="cross"></Icon>
                              </div>
                            ) :
                            (
                              <div className="form-icon form-icon-right">
                                <Icon name="mail"></Icon>
                              </div>
                            )
                        }
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by Email/Ticket ID"
                          value={onSearchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={handleKeyDown}
                          style={{ width: "200px" }}
                        />
                      </div>
                    </li>
                    <li>
                      <UncontrolledDropdown className="ml-1">
                        <DropdownToggle tag="div" color="transparent">
                          <a
                            href="#toggle"
                            onClick={(ev) => { ev.preventDefault(); onSearchEnteredText(); }}
                            className="btn btn-primary btn-dim d-none d-sm-inline-flex"
                          >
                            <Icon className="d-none d-sm-inline" name="search" />
                          </a>
                        </DropdownToggle>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown className="ml-1">
                        <DropdownToggle
                          outline
                          color="secondary"
                          className="dropdown-toggle dropdown-indicator btn-dim  flex"
                        >
                          Ticket Status{" "}
                        </DropdownToggle>
                        <DropdownMenu>
                          <ul className="link-check">
                            <li>
                              <span>Select status</span>
                            </li>
                            <li
                              className={selectedStatus === "all" && "active"}
                              onClick={() => setSelectedStatus("all")}
                            >
                              <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                All
                              </a>
                            </li>
                            <li
                              className={selectedStatus === "1" && "active"}
                              onClick={() => setSelectedStatus("1")}
                            >
                              <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                Booked
                              </a>
                            </li>
                            <li
                              className={selectedStatus === "2" && "active"}
                              onClick={() => setSelectedStatus("2")}
                            >
                              <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                Activated
                              </a>
                            </li>
                            <li
                              className={selectedStatus === "3" && "active"}
                              onClick={() => setSelectedStatus("3")}
                            >
                              <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                Validated
                              </a>
                            </li>
                            <li
                              className={selectedStatus === "4" && "active"}
                              onClick={() => setSelectedStatus("4")}
                            >
                              <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                Expired
                              </a>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>

                  <div>
                    {/* <UncontrolledDropdown className="ml-2">
                        <DropdownToggle tag="div" color="transparent">
                          <a
                            href="#toggle"
                            onClick={(ev) => { ev.preventDefault(); onSearchEnteredText(); }}
                            className="btn btn-primary btn-dim d-none d-sm-inline-flex"
                          >
                            <Icon className="d-none d-sm-inline" name="search" />
                          </a>
                        </DropdownToggle>
                      </UncontrolledDropdown> */}
                    {/* 
{ onSearchText &&
  <UncontrolledDropdown className="ml-1">
    <DropdownToggle tag="div" color="transparent">
      <a
        href="#toggle"
        onClick={(ev) => { ev.preventDefault(); handleClearSearch() }}
        className="btn btn-danger btn-dim d-none d-sm-inline-flex"
      >
        <Icon className="d-none d-sm-inline" name="cross" />
      </a>
    </DropdownToggle>
  </UncontrolledDropdown>
} */}
                  </div>

                </div>
                {searchLengthErr && <p className="mt-1" style={{ color: "red", fontSize: "12px", fontStyle: "italic" }}>Minimum 4 digits required</p>}
                {noResultsFound && (
                  <div style={{ marginTop: 20 }}>
                    <Card>
                      <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                          <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                            <div>
                              <h6 className="title " style={{ textAlign: "center" }}>
                                No tickets Found
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              </div>
            </Col>
            {/* <Col className="col-lg-4 text-right" sm="8">
              <div className="datatable-filter">
                <div className="d-flex justify-content-end g-2">
                  {actions && <Export data={ticketHistory} startDate={exportStart} endDate={exportEnd} clientValueLabel={clientValueLabel} user={user} userrole={userrole} logoUrl={logoUrl} />}
                </div>
              </div>
            </Col> */}
          </Row>
          <Card className="mt-2">
            <div className="nk-ecwg nk-ecwg6">
              <div className="card-inner">
                <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <h6 className="title " style={{ textAlign: "center" }}>
                      No Tickets Available
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
  }

  if (apiCount === 0 && status === "loading") {
    return (
      <Card>
        <div className="nk-ecwg nk-ecwg6">
          <div className="card-inner">
            <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <h6 className="title " style={{ textAlign: "center" }}>
                  <Spinner color="primary" />
                </h6>
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  if (ticketData && ticketData.length > 0) {
    return (
      <div className={`dt-bootstrap4 no-footer ${className ? className : ""}`}>
        <Row className="gy-4">
          <Col sm={8}>
            <FormGroup>
              {/* <Label>Date Range</Label> */}
              <div className="d-flex align-items-end" style={{ marginTop: "-5px", marginBottom: 10 }}>
                <div style={{ textAlign: "right" }}>
                  <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="dropdown-container">
                    <DropdownToggle
                      color="primary"
                      className="dropdown-toggle"
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                    >
                      <Icon style={{ paddingRight: "5px" }} name="calender-date" />
                      {selectedOption}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu">
                      <DropdownItem onClick={() => handleDropdownChange("Today")}>Today</DropdownItem>
                      <DropdownItem onClick={() => handleDropdownChange("Last Week")}>Last Week</DropdownItem>
                      <DropdownItem onClick={() => handleDropdownChange("Last Month")}>Last Month</DropdownItem>
                      <DropdownItem onClick={() => handleDropdownChange("Last 3 Months")}>Last 3 Months</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="ml-2">
                  <DatePicker
                    selected={rangeDate.start}
                    startDate={rangeDate.start}
                    onChange={onRangeChange}
                    endDate={rangeDate.end}
                    selectsRange
                    className="form-control date-picker"
                  />
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        {!error ? (
          <React.Fragment>
            <Row className={`justify-between align-items-end g-2 ${actions ? "with-export" : ""}`}>
              <Col className="col-lg-8 text-left" sm="4">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  {/* <label>
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder="Search by Ticket ID"
                      onChange={(e) => onFilterChange(e)}
                    />
                  </label> */}
                  {/* <ul className="nk-block-tools g-3">
                    <li style={{ width: "80%" }}>
                      <div className="form-control-wrap" style={{ width: "80%" }}>
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by Email"
                          onChange={(e) => onFilterChange(e)}
                        />
                      </div>
                    </li>
                  </ul> */}
                  <div className="d-flex align-items-start justify-content-start">

                    <ul className="nk-block-tools">
                      <li>
                        <div className="form-control-wrap">
                          {onSearchText ? (
                            <div className="form-icon form-icon-right" onClick={() => setSearchText("")}>
                              <Icon name="cross"></Icon>
                            </div>
                          ) : (
                            <div className="form-icon form-icon-right">
                              <Icon name="mail"></Icon>
                            </div>
                          )}
                          <input
                            type="text"
                            className="form-control"
                            id="default-04"
                            placeholder="Search by Email/Ticket ID"
                            value={onSearchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={{ width: "200px" }}
                          />
                        </div>
                      </li>
                      <li>
                        <UncontrolledDropdown className="ml-1">
                          <DropdownToggle tag="div" color="transparent">
                            <a
                              href="#toggle"
                              onClick={(ev) => { ev.preventDefault(); onSearchEnteredText(); }}
                              className="btn btn-primary btn-dim d-none d-sm-inline-flex"
                            >
                              <Icon className="d-none d-sm-inline" name="search" />
                            </a>
                          </DropdownToggle>
                        </UncontrolledDropdown>
                      </li>
                      <li>
                        <UncontrolledDropdown className="ml-1">
                          <DropdownToggle
                            outline
                            color="secondary"
                            className="dropdown-toggle dropdown-indicator btn-dim  flex"
                          >
                            Ticket Status{" "}
                          </DropdownToggle>
                          <DropdownMenu>
                            <ul className="link-check">
                              <li>
                                <span>Select status</span>
                              </li>
                              <li
                                className={selectedStatus === "all" && "active"}
                                onClick={() => setSelectedStatus("all")}
                              >
                                <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                  All
                                </a>
                              </li>
                              <li
                                className={selectedStatus === "1" && "active"}
                                onClick={() => setSelectedStatus("1")}
                              >
                                <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                  Booked
                                </a>
                              </li>
                              <li
                                className={selectedStatus === "2" && "active"}
                                onClick={() => setSelectedStatus("2")}
                              >
                                <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                  Activated
                                </a>
                              </li>
                              <li
                                className={selectedStatus === "3" && "active"}
                                onClick={() => setSelectedStatus("3")}
                              >
                                <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                  Validated
                                </a>
                              </li>
                              <li
                                className={selectedStatus === "4" && "active"}
                                onClick={() => setSelectedStatus("4")}
                              >
                                <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                  Expired
                                </a>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>

                    <div>
                      {/* <UncontrolledDropdown className="ml-2">
                        <DropdownToggle tag="div" color="transparent">
                          <a
                            href="#toggle"
                            onClick={(ev) => {
                              ev.preventDefault();
                              onSearchEnteredText();
                            }}
                            className="btn btn-primary btn-dim d-none d-sm-inline-flex"
                          >
                            <Icon className="d-none d-sm-inline" name="search" />
                          </a>
                        </DropdownToggle>
                      </UncontrolledDropdown> */}
                      {/* 
{ onSearchText &&
  <UncontrolledDropdown className="ml-1">
    <DropdownToggle tag="div" color="transparent">
      <a
        href="#toggle"
        onClick={(ev) => { ev.preventDefault(); handleClearSearch() }}
        className="btn btn-danger btn-dim d-none d-sm-inline-flex"
      >
        <Icon className="d-none d-sm-inline" name="cross" />
      </a>
    </DropdownToggle>
  </UncontrolledDropdown>
} */}
                    </div>
                  </div>
                  {searchLengthErr && (
                    <p className="mt-1" style={{ color: "red", fontSize: "12px", fontStyle: "italic" }}>
                      Minimum 4 digits required
                    </p>
                  )}
                  {noResultsFound && (
                    <div style={{ marginTop: 20 }}>
                      <Card>
                        <div className="nk-ecwg nk-ecwg6">
                          <div className="card-inner">
                            <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                              <div>
                                <h6 className="title " style={{ textAlign: "center" }}>
                                  No tickets Found
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
              </Col>
              <Col className="col-lg-4 text-right" sm="8">
                <div className="datatable-filter">
                  <div className="d-flex justify-content-end g-2">
                    {actions && (
                      <Export
                        data={ticketHistory}
                        startDate={exportStart}
                        endDate={exportEnd}
                        clientValueLabel={clientValueLabel}
                        user={user}
                        userrole={userrole}
                        logoUrl={logoUrl}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            {searchedTicketData.length > 0 || onSearchText === "" ? (
              <>
                {paginationLoading && (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                )}
                <DataTable
                  data={searchedTicketData.length > 0 ? searchedTicketData : ticketData}
                  columns={columns}
                  className={className}
                  selectableRows={selectableRows}
                  selectableRowsComponent={CustomCheckbox}
                  expandableRowsComponent={ExpandableRowComponent}
                  expandableRows={true}
                  expandableRowExpanded={() => true}
                  noDataComponent={<div className="p-2">There are no records found</div>}
                  sortIcon={
                    <div>
                      <span>&darr;</span>
                      <span>&uarr;</span>
                    </div>
                  }
                  defaultSortField="PurchasedDate"
                  defaultSortAsc={false}
                  {...(currentPage && { currentPage })}
                />

                <div className="pagination">
                  <button className="btn btn-primary" onClick={handlePrevPage} disabled={currentPage === 1}>
                    Previous
                  </button>
                  {startPage > 1 && (
                    <button
                      className={`btn btn-primary ${1 === currentPage ? "active" : ""}`}
                      onClick={() => handlePageClick(1)}
                    >
                      1
                    </button>
                  )}
                  {currentPage > 3 && totalPage > 5 && <span className="ellipsis">...</span>}
                  {pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
                    <button
                      key={pageNumber}
                      className={`btn btn-primary ${pageNumber === currentPage ? "active" : ""}`}
                      onClick={() => handlePageClick(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  ))}
                  {currentPage < totalPage - 2 && totalPage > 5 && <span className="ellipsis">...</span>}
                  {endPage < totalPage && (
                    <button
                      className={`btn btn-primary ${totalPage === currentPage ? "active" : ""}`}
                      onClick={() => handlePageClick(totalPage)}
                    >
                      {totalPage}
                    </button>
                  )}
                  {currentPage < totalPage && (
                    <button className="btn btn-primary" onClick={handleNextPage}>
                      Next
                    </button>
                  )}
                </div>
              </>
            ) : (
              <Card className="mt-2">
                <div className="nk-ecwg nk-ecwg6">
                  <div className="card-inner">
                    <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                      <div>
                        <h6 className="title " style={{ textAlign: "center" }}>
                          No Tickets found
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </React.Fragment>
        ) : (
          <Card>
            <div className="nk-ecwg nk-ecwg6">
              <div className="card-inner">
                <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <h6 className="title " style={{ textAlign: "center" }}>
                      ERROR : No data found
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Row className="gy-4">
          <Col sm={4}>
            <FormGroup>
              <Label>Date</Label>
              <div className="form-control-wrap">
                <DatePicker
                  selected={rangeDate.start}
                  startDate={rangeDate.start}
                  onChange={onRangeChange}
                  endDate={rangeDate.end}
                  selectsRange
                  className="form-control date-picker"
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Card>
          <div className="nk-ecwg nk-ecwg6">
            <div className="card-inner">
              <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <h6 className="title " style={{ textAlign: "center" }}>
                    No tickets Found
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
};
export default TicketsTable;

const exportColumns = [
  {
    name: "Ticket ID",
    selector: (row) => row.TicketID,
    sortable: true,
  },
  {
    name: "Transaction ID",
    selector: (row) => row.TransactionId,
    sortable: true,
    grow: 1,
  },
  {
    name: "Email",
    selector: (row) => row.EmailId,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => "$ " + row.Amount.toFixed(2),
    sortable: true,
  },
  {
    name: "Purchased Date",
    selector: (row) =>
      new Date(row.PurchasedDate).getFullYear() >= 2001 ? moment(row.PurchasedDate).format("M/D/YYYY, h:mm:ss A") : "-",
    sortable: true,
    grow: 3,
  },
  {
    name: "Activated",
    selector: (row) => (row.Activated ? "Yes" : "No"),
    sortable: true,
  },
  {
    name: "Activated Date",
    selector: (row) =>
      // new Date(row.ActivatedDate).getFullYear() >= 2001 ? moment(row.ActivatedDate).format("M/D/YYYY, h:mm:ss A") : "-",
      row.Activated
        ? new Date(row.ActivatedDate).getFullYear() >= 2001
          ? moment(row.ActivatedDate).format("M/D/YYYY, h:mm:ss A")
          : moment(row.ValidatedDate).format("M/D/YYYY, h:mm:ss A")
        : "-",
    sortable: true,
    grow: 3,
    sortField: "ActivatedDate",
  },
  {
    name: "Validated",
    selector: (row) => (row.Validated ? "Yes" : "No"),
    sortable: true,
  },
  {
    name: "Validated Date",
    selector: (row) =>
      new Date(row.ValidatedDate).getFullYear() >= 2001 ? moment(row.ValidatedDate).format("M/D/YYYY, h:mm:ss A") : "-",
    sortable: true,
    grow: 2,
    sortField: "ValidatedDate",
  },
  {
    name: "Expiry Date",
    selector: (row) =>
      new Date(row.ExpiryDate).getFullYear() >= 2001 ? moment(row.ExpiryDate).format("M/D/YYYY, h:mm:ss A") : "-",
    sortable: true,
    grow: 2,
    sortField: "ExpiryDate",
  },
];
